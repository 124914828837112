import React, { useState, useReducer, useEffect, useMemo, useCallback } from 'react'

import NavigationTabs from 'components/NavigationTabs'

import BigNumber from 'bignumber.js'
import { createBrowserHistory } from 'history'
import { ethers } from 'ethers'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useWeb3React } from 'hooks'
import { MAIN_TOKEN } from '../../constants'
import { amountFormatter, isAddress, formatNasBalance } from 'utils'

// import { useExchangeContract } from 'hooks'
import { useTokenDetails } from 'contexts/Tokens'
import { useTransactionAdder, useAllTransactions } from 'contexts/Transactions'
import { useAddressBalance } from 'contexts/Balances'
import { useSwapReserves } from 'hooks/useSwapReserves'
import { useAddressAllowance } from 'contexts/Allowances'
// import { useWalletModalToggle } from 'contexts/Application'

import { Button } from 'theme'
import CurrencyInputPanel from '../CurrencyInputPanel'
import AddressInputPanel from '../AddressInputPanel'
import OversizedPanel from '../OversizedPanel'
import TransactionDetails from '../TransactionDetails'
import ArrowDown from 'assets/svg/SVGArrowDown'
import { useTokenDetailBySymbol } from 'contexts/Tokens'

import { useSwap } from 'hooks/useSwap'

// modal
import { useModal } from 'hooks/useModal'
import WalletProviderModal from 'components/WalletProviderModal'
import CallContractModal from 'components/CallContractModal'

import { Spinner } from 'theme'
// import Circle from 'assets/images/circle.svg'

const { bigNumberify } = ethers.utils

const INPUT = 0
const OUTPUT = 1

const NAS_TO_TOKEN = 0
const TOKEN_TO_NAS = 1
const TOKEN_TO_TOKEN = 2
const NAS_TO_WNAS = 3
const WNAS_TO_NAS = 4

// denominated in bips
const ALLOWED_SLIPPAGE_DEFAULT = 50
const TOKEN_ALLOWED_SLIPPAGE_DEFAULT = 50

// 15 minutes, denominated in seconds
// const DEFAULT_DEADLINE_FROM_NOW = 60 * 15

// % above the calculated gas cost that we actually send, denominated in bips
// const GAS_MARGIN = bigNumberify(1000)

const DownArrowBackground = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  align-items: center;
`

const WrappedArrowDown = ({ clickable, active, ...rest }) => <ArrowDown {...rest} />
const DownArrow = styled(WrappedArrowDown)`
  color: ${({ theme, active }) => (active ? theme.royalBlue : theme.chaliceGray)};
  width: 0.625rem;
  height: 0.625rem;
  position: relative;
  padding: 0.875rem;
  cursor: ${({ clickable }) => clickable && 'pointer'};
`

const ExchangeRateWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  color: ${({ theme }) => theme.doveGray};
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
`

const ExchangeRate = styled.span`
  flex: 1 1 auto;
  width: 0;
  color: ${({ theme }) => theme.doveGray};
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;

  button {
    max-width: 20rem;
  }
`

// const SpinnerWrapper = styled(Spinner)`
//   margin: 0 0.25rem 0 0.25rem;
// `

function calculateSlippageBounds(value, token = false, tokenAllowedSlippage, allowedSlippage) {
  if (value) {
    const offset = value.mul(token ? tokenAllowedSlippage : allowedSlippage).div(bigNumberify(10000))
    const minimum = value.sub(offset)
    const maximum = value.add(offset)
    return {
      minimum: minimum.lt(ethers.constants.Zero) ? ethers.constants.Zero : minimum,
      maximum: maximum.gt(ethers.constants.MaxUint256) ? ethers.constants.MaxUint256 : maximum
    }
  } else {
    return {}
  }
}

// this mocks the getInputPrice function, and calculates the required output
function calculateOutputFromInput(inputAmount, inputReserve, outputReserve) {
  const inputReservBig = bigNumberify(inputReserve)
  const outputReserveBig = bigNumberify(outputReserve)

  const inputAmountWithFee = inputAmount.mul(bigNumberify(997))
  const numerator = inputAmountWithFee.mul(outputReserveBig)
  const denominator = inputReservBig.mul(bigNumberify(1000)).add(inputAmountWithFee)
  return numerator.div(denominator)
}

// this mocks the getOutputPrice function, and calculates the required input
function calculateInputFromOutput(outputAmount, inputReserve, outputReserve) {
  const inputReservBig = bigNumberify(inputReserve)
  const outputReserveBig = bigNumberify(outputReserve)

  const numerator = inputReservBig.mul(outputAmount).mul(bigNumberify(1000))
  const denominator = outputReserveBig.sub(outputAmount).mul(bigNumberify(997))
  return numerator.div(denominator).add(ethers.constants.One)
}

function getInitialSwapState(state) {
  return {
    independentValue: state.exactFieldURL && state.exactAmountURL ? state.exactAmountURL : '', // this is a user input
    dependentValue: '', // this is a calculated number
    independentField: state.exactFieldURL === 'output' ? OUTPUT : INPUT,
    inputCurrency: state.inputCurrencyURL ? state.inputCurrencyURL : MAIN_TOKEN,
    outputCurrency: state.outputCurrencyURL
      ? state.outputCurrencyURL === MAIN_TOKEN
        ? state.inputCurrencyURL && state.inputCurrencyURL !== MAIN_TOKEN
          ? MAIN_TOKEN
          : ''
        : state.outputCurrencyURL
      : state.initialCurrency
      ? state.initialCurrency
      : ''
  }
}

function swapStateReducer(state, action) {
  switch (action.type) {
    case 'FLIP_INDEPENDENT': {
      const { independentField, inputCurrency, outputCurrency } = state
      return {
        ...state,
        dependentValue: '',
        independentField: independentField === INPUT ? OUTPUT : INPUT,
        inputCurrency: outputCurrency,
        outputCurrency: inputCurrency
      }
    }
    case 'SELECT_CURRENCY': {
      const { inputCurrency, outputCurrency } = state
      const { field, currency } = action.payload

      console.log('SELECT_CURRENCY', { inputCurrency, outputCurrency, field, currency })

      const newInputCurrency = field === INPUT ? currency : inputCurrency
      const newOutputCurrency = field === OUTPUT ? currency : outputCurrency

      if (newInputCurrency === newOutputCurrency) {
        return {
          ...state,
          inputCurrency: field === INPUT ? currency : '',
          outputCurrency: field === OUTPUT ? currency : ''
        }
      } else {
        return {
          ...state,
          inputCurrency: newInputCurrency,
          outputCurrency: newOutputCurrency
        }
      }
    }
    case 'UPDATE_INDEPENDENT': {
      const { field, value } = action.payload
      const { dependentValue, independentValue } = state
      return {
        ...state,
        independentValue: value,
        dependentValue: value === independentValue ? dependentValue : '',
        independentField: field
      }
    }
    case 'UPDATE_DEPENDENT': {
      return {
        ...state,
        dependentValue: action.payload
      }
    }
    default: {
      return getInitialSwapState()
    }
  }
}

function getExchangeRate(inputValue, inputDecimals, outputValue, outputDecimals, invert = false) {
  try {
    if (
      inputValue &&
      (inputDecimals || inputDecimals === 0) &&
      outputValue &&
      (outputDecimals || outputDecimals === 0)
    ) {
      const factor = bigNumberify(10).pow(bigNumberify(6))

      if (invert) {
        return inputValue
          .mul(factor)
          .mul(bigNumberify(10).pow(bigNumberify(outputDecimals)))
          .div(bigNumberify(10).pow(bigNumberify(inputDecimals)))
          .div(outputValue)
      } else {
        return outputValue
          .mul(factor)
          .mul(bigNumberify(10).pow(bigNumberify(inputDecimals)))
          .div(bigNumberify(10).pow(bigNumberify(outputDecimals)))
          .div(inputValue)
      }
    }
  } catch {
    console.log('getExchangeRate error', {
      inputValue,
      inputDecimals,
      outputValue,
      outputDecimals
    })
  }
}

function getMarketRate(swapType, inputReserve, inputDecimals, outputReserve, outputDecimals) {
  if (swapType === NAS_TO_TOKEN) {
    return getExchangeRate(inputReserve, inputDecimals, outputReserve, outputDecimals)
  } else if (swapType === TOKEN_TO_NAS) {
    return getExchangeRate(inputReserve, inputDecimals, outputReserve, outputDecimals)
  } else if (swapType === TOKEN_TO_TOKEN) {
    return getExchangeRate(inputReserve, inputDecimals, outputReserve, outputDecimals)
  } else if (swapType === NAS_TO_WNAS || swapType === WNAS_TO_NAS) {
    return getExchangeRate(swapType, inputReserve, inputDecimals, outputReserve, outputDecimals)
  }
}

export default function ExchangePage({ initialCurrency, sending = false, params }) {
  const { t } = useTranslation()
  const { useNano, account, error } = useWeb3React()

  const urlAddedTokens = {}
  if (params.inputCurrency) {
    urlAddedTokens[params.inputCurrency] = true
  }
  if (params.outputCurrency) {
    urlAddedTokens[params.outputCurrency] = true
  }
  if (isAddress(initialCurrency)) {
    urlAddedTokens[initialCurrency] = true
  }

  const addTransaction = useTransactionAdder()

  // check if URL specifies valid slippage, if so use as default
  const initialSlippage = (token = false) => {
    let slippage = Number.parseInt(params.slippage)
    if (!isNaN(slippage) && (slippage === 0 || slippage >= 1)) {
      return slippage // round to match custom input availability
    }
    // check for token <-> token slippage option
    return token ? TOKEN_ALLOWED_SLIPPAGE_DEFAULT : ALLOWED_SLIPPAGE_DEFAULT
  }

  // check URL params for recipient, only on send page
  const initialRecipient = () => {
    if (sending && params.recipient) {
      return params.recipient
    }
    return ''
  }

  // const [brokenTokenWarning, setBrokenTokenWarning] = useState()

  // const [deadlineFromNow, setDeadlineFromNow] = useState(DEFAULT_DEADLINE_FROM_NOW)

  const [rawSlippage, setRawSlippage] = useState(() => initialSlippage())
  const [rawTokenSlippage, setRawTokenSlippage] = useState(() => initialSlippage(true))

  const allowedSlippageBig = bigNumberify(rawSlippage)
  const tokenAllowedSlippageBig = bigNumberify(rawTokenSlippage)

  // core swap state
  const [swapState, dispatchSwapState] = useReducer(
    swapStateReducer,
    {
      initialCurrency: initialCurrency,
      inputCurrencyURL: params.inputCurrency,
      outputCurrencyURL: params.outputCurrency,
      exactFieldURL: params.exactField,
      exactAmountURL: params.exactAmount
    },
    getInitialSwapState
  )

  const { independentValue, dependentValue, independentField, inputCurrency, outputCurrency } = swapState

  const [recipient, setRecipient] = useState({
    address: initialRecipient(),
    name: ''
  })
  const [recipientError, setRecipientError] = useState(null)

  const wNAS = useTokenDetailBySymbol('wNAS')

  function getSwapType(inputCurrency, outputCurrency) {
    if (!inputCurrency || !outputCurrency) {
      return null
    } else if (inputCurrency === MAIN_TOKEN) {
      if (outputCurrency === wNAS.exchangeAddress) {
        return NAS_TO_WNAS
      }
      return NAS_TO_TOKEN
    } else if (outputCurrency === MAIN_TOKEN) {
      if (inputCurrency === wNAS.exchangeAddress) {
        return WNAS_TO_NAS
      }
      return TOKEN_TO_NAS
    } else {
      return TOKEN_TO_TOKEN
    }
  }

  // get swap type from the currency types
  const swapType = getSwapType(inputCurrency, outputCurrency)

  // get decimals and exchange address for each of the currency types
  const { symbol: inputSymbol, decimals: inputDecimals } = useTokenDetails(inputCurrency)
  const { symbol: outputSymbol, decimals: outputDecimals } = useTokenDetails(outputCurrency)

  // const inputExchangeContract = useExchangeContract(inputExchangeAddress)
  // const outputExchangeContract = useExchangeContract(outputExchangeAddress)
  // const contract = swapType === NAS_TO_TOKEN ? outputExchangeContract : inputExchangeContract

  // get input allowance
  const inputAllowance = useAddressAllowance(account, inputCurrency)

  // fetch reserves for each of the currency types
  const { reserve1: inputReserve, reserve2: outputReserve, fetchPairInfo } = useSwapReserves(
    inputCurrency,
    outputCurrency
  )

  // get balances for each of the currency types
  const inputBalance = useAddressBalance(account, inputCurrency)
  const outputBalance = useAddressBalance(account, outputCurrency)
  const inputBalanceFormatted = !!(inputBalance && Number.isInteger(inputDecimals))
    ? amountFormatter(inputBalance, inputDecimals, Math.min(4, inputDecimals))
    : ''
  const outputBalanceFormatted = !!(outputBalance && Number.isInteger(outputDecimals))
    ? amountFormatter(outputBalance, outputDecimals, Math.min(4, outputDecimals))
    : ''

  // compute useful transforms of the data above
  const independentDecimals = independentField === INPUT ? inputDecimals : outputDecimals
  const dependentDecimals = independentField === OUTPUT ? inputDecimals : outputDecimals

  // declare/get parsed and formatted versions of input/output values
  const [independentValueParsed, setIndependentValueParsed] = useState()
  const dependentValueFormatted = !!(dependentValue && (dependentDecimals || dependentDecimals === 0))
    ? amountFormatter(dependentValue, dependentDecimals, dependentDecimals, false)
    : ''
  const inputValueParsed = independentField === INPUT ? independentValueParsed : dependentValue
  const inputValueFormatted = independentField === INPUT ? independentValue : dependentValueFormatted
  const outputValueParsed = independentField === OUTPUT ? independentValueParsed : dependentValue
  const outputValueFormatted = independentField === OUTPUT ? independentValue : dependentValueFormatted

  // validate + parse independent value
  const [independentError, setIndependentError] = useState()
  useEffect(() => {
    if (independentValue && (independentDecimals || independentDecimals === 0)) {
      try {
        const parsedValue = ethers.utils.parseUnits(independentValue, independentDecimals)

        if (parsedValue.lte(ethers.constants.Zero) || parsedValue.gte(ethers.constants.MaxUint256)) {
          throw Error()
        } else {
          setIndependentValueParsed(parsedValue)
          setIndependentError(null)
        }
      } catch (err) {
        console.error(err)
        setIndependentError(t('inputNotValid'))
      }

      return () => {
        setIndependentValueParsed()
        setIndependentError()
      }
    }
  }, [independentValue, independentDecimals, t])

  // calculate slippage from target rate
  const { minimum: dependentValueMinumum, maximum: dependentValueMaximum } = calculateSlippageBounds(
    dependentValue,
    swapType === TOKEN_TO_TOKEN,
    tokenAllowedSlippageBig,
    allowedSlippageBig
  )

  // validate input allowance + balance
  const [inputError, setInputError] = useState()
  const [showUnlock, setShowUnlock] = useState(false)
  useEffect(() => {
    const inputValueCalculation = independentField === INPUT ? independentValueParsed : dependentValueMaximum
    if (inputBalance && (inputAllowance || inputCurrency === MAIN_TOKEN) && inputValueCalculation) {
      if (inputBalance.lt(inputValueCalculation)) {
        setInputError(t('insufficientBalance'))
      } else if (inputCurrency !== MAIN_TOKEN && inputAllowance.lt(inputValueCalculation)) {
        setInputError(t('unlockTokenCont'))
        setShowUnlock(true)
      } else {
        setInputError(null)
        setShowUnlock(false)
      }
      return () => {
        setInputError()
        setShowUnlock(false)
      }
    }
  }, [independentField, independentValueParsed, dependentValueMaximum, inputBalance, inputCurrency, inputAllowance, t])

  // update swap reserve
  const updateReserve = useCallback(() => {
    if (inputCurrency && outputCurrency) {
      fetchPairInfo(inputCurrency, outputCurrency)
    }
  }, [fetchPairInfo, inputCurrency, outputCurrency])

  // when input, output balance change, update reserve
  useEffect(() => {
    updateReserve()
  }, [inputBalance, outputBalance, updateReserve])

  // calculate dependent value
  useEffect(() => {
    const amount = independentValueParsed

    console.log('calculate dependent value', {
      swapType,
      inputReserve,
      outputReserve,
      amount: amount && amount.toString()
    })

    // clear independent error
    // setIndependentError();

    if (swapType === NAS_TO_TOKEN) {
      const reserveNAS = inputReserve
      const reserveToken = outputReserve

      if (amount && reserveNAS && reserveToken) {
        try {
          const calculatedDependentValue =
            independentField === INPUT
              ? calculateOutputFromInput(amount, reserveNAS, reserveToken)
              : calculateInputFromOutput(amount, reserveNAS, reserveToken)

          if (calculatedDependentValue.lte(ethers.constants.Zero)) {
            throw calculatedDependentValue.toString()
          } else {
            setIndependentError(null)
          }

          dispatchSwapState({
            type: 'UPDATE_DEPENDENT',
            payload: calculatedDependentValue
          })
        } catch (err) {
          console.log('NAS_TO_TOKEN err', err)
          setIndependentError(t('insufficientLiquidity'))
        }
        return () => {
          dispatchSwapState({ type: 'UPDATE_DEPENDENT', payload: '' })
        }
      }
    } else if (swapType === TOKEN_TO_NAS) {
      const reserveNAS = outputReserve
      const reserveToken = inputReserve

      if (amount && reserveNAS && reserveToken) {
        try {
          const calculatedDependentValue =
            independentField === INPUT
              ? calculateOutputFromInput(amount, reserveToken, reserveNAS)
              : calculateInputFromOutput(amount, reserveToken, reserveNAS)

          if (calculatedDependentValue.lte(ethers.constants.Zero)) {
            throw calculatedDependentValue.toString()
          } else {
            setIndependentError(null)
          }

          dispatchSwapState({
            type: 'UPDATE_DEPENDENT',
            payload: calculatedDependentValue
          })
        } catch (err) {
          console.log('TOKEN_TO_NAS err', err)
          setIndependentError(t('insufficientLiquidity'))
        }
        return () => {
          dispatchSwapState({ type: 'UPDATE_DEPENDENT', payload: '' })
        }
      }
    } else if (swapType === TOKEN_TO_TOKEN) {
      if (amount && inputReserve && outputReserve) {
        try {
          const calculatedDependentValue =
            independentField === INPUT
              ? calculateOutputFromInput(amount, inputReserve, outputReserve)
              : calculateInputFromOutput(amount, inputReserve, outputReserve)

          if (calculatedDependentValue.lte(ethers.constants.Zero)) {
            throw calculatedDependentValue.toString()
          } else {
            setIndependentError(null)
          }

          dispatchSwapState({
            type: 'UPDATE_DEPENDENT',
            payload: calculatedDependentValue
          })
        } catch (err) {
          console.log('TOKEN_TO_TOKEN err', err)
          setIndependentError(t('insufficientLiquidity'))
        }
        return () => {
          dispatchSwapState({ type: 'UPDATE_DEPENDENT', payload: '' })
        }
      }
    } else if (swapType === NAS_TO_WNAS || swapType === WNAS_TO_NAS) {
      // const reserveNAS = inputReserve
      // const reserveToken = outputReserve

      if (amount) {
        try {
          const calculatedDependentValue = independentField === INPUT ? amount : amount

          if (calculatedDependentValue.lte(ethers.constants.Zero)) {
            throw calculatedDependentValue.toString()
          } else {
            setIndependentError(null)
          }

          dispatchSwapState({
            type: 'UPDATE_DEPENDENT',
            payload: calculatedDependentValue
          })
        } catch (err) {
          console.log('NAS_TO_WNAS,WNAS_TO_NAS  err', err)
          setIndependentError(t('insufficientLiquidity'))
        }
        return () => {
          dispatchSwapState({ type: 'UPDATE_DEPENDENT', payload: '' })
        }
      }
    }
  }, [independentValueParsed, swapType, inputReserve, outputReserve, independentField, t])

  useEffect(() => {
    const history = createBrowserHistory()
    history.push(window.location.pathname + '')
  }, [])

  const [inverted, setInverted] = useState(false)
  const exchangeRate = getExchangeRate(inputValueParsed, inputDecimals, outputValueParsed, outputDecimals)
  const exchangeRateInverted = getExchangeRate(inputValueParsed, inputDecimals, outputValueParsed, outputDecimals, true)

  // const marketRate = getMarketRate(
  //   swapType,
  //   inputReserve, outputReserve,
  //   inputDecimals,
  //   outputDecimals
  // )

  // market rate
  const marketRate = useMemo(() => {
    // console.log('marketRate swapType', swapType)

    if (swapType === NAS_TO_WNAS || swapType === WNAS_TO_NAS) {
      return getMarketRate(swapType, 1, inputDecimals, 1, outputDecimals)
    }

    if (inputReserve && outputReserve) {
      const inputReserveBig = bigNumberify(inputReserve)
      const outputReserveBig = bigNumberify(outputReserve)

      let rate = getMarketRate(swapType, inputReserveBig, inputDecimals, outputReserveBig, outputDecimals)

      if (rate) {
        console.log('marketRate calculate', {
          rate: rate.toString(),
          inputCurrency,
          outputCurrency,
          inputReserve: inputReserve && inputReserve.toString(),
          outputReserve: outputReserve && outputReserve.toString(),
          inputDecimals,
          outputDecimals
        })
      }

      return rate
    }
  }, [swapType, inputCurrency, outputCurrency, inputReserve, outputReserve, inputDecimals, outputDecimals])

  const percentSlippage =
    exchangeRate && marketRate && !marketRate.isZero()
      ? exchangeRate
          .sub(marketRate)
          .abs()
          .mul(bigNumberify(10).pow(bigNumberify(6)))
          .div(marketRate)
      : // .sub(bigNumberify(3).mul(bigNumberify(10).pow(bigNumberify(15))))
        undefined
  const percentSlippageFormatted = percentSlippage && amountFormatter(percentSlippage, 4, 2)
  const slippageWarning =
    percentSlippage &&
    percentSlippage.gte(ethers.utils.parseEther('.05')) &&
    percentSlippage.lt(ethers.utils.parseEther('.2')) // [5% - 20%)
  const highSlippageWarning = percentSlippage && percentSlippage.gte(ethers.utils.parseEther('.2')) // [20+%

  const isValid = sending
    ? exchangeRate && inputError === null && independentError === null && recipientError === null
    : exchangeRate && inputError === null && independentError === null

  const estimatedText = `(${t('estimated')})`
  // const estimatedText = `remove me`
  function formatBalance(value) {
    return `${t('balance', { balanceInput: value })}`
  }

  const { getContractParams: getSwapContractParams, onSwap } = useSwap()

  // transaction status
  const allTransactions = useAllTransactions()
  const pending = Object.keys(allTransactions).filter(hash => !allTransactions[hash].receipt)
  // const confirmed = Object.keys(allTransactions).filter(hash => allTransactions[hash].receipt)

  // const hasPendingTransactions = !!pending.length

  // show swap qrcode modal
  const [onShowSwapModal] = useModal(<CallContractModal title={t('swap')} />)

  async function onClickSwap() {
    if (inputCurrency === outputCurrency) {
      return
    }

    let path = []
    let amountIn,
      amountOut,
      nasValue = 0

    if (independentField === INPUT) {
      if (swapType === NAS_TO_TOKEN) {
        path = [wNAS.exchangeAddress, outputCurrency]
        amountIn = inputValueParsed
        amountOut = dependentValueMinumum
        nasValue = formatNasBalance(inputValueParsed)
      }

      if (swapType === TOKEN_TO_NAS) {
        path = [inputCurrency, wNAS.exchangeAddress]
        amountIn = inputValueParsed
        amountOut = dependentValueMinumum
      }

      if (swapType === TOKEN_TO_TOKEN) {
        path = [inputCurrency, outputCurrency]
        amountIn = inputValueParsed
        amountOut = dependentValueMinumum
      }

      if (swapType === NAS_TO_WNAS) {
        amountIn = inputValueParsed
        amountOut = dependentValueMinumum
        nasValue = formatNasBalance(inputValueParsed)
      }

      if (swapType === WNAS_TO_NAS) {
        amountIn = inputValueParsed
        amountOut = dependentValueMinumum
      }
    } else if (independentField === OUTPUT) {
      if (swapType === NAS_TO_TOKEN) {
        path = [wNAS.exchangeAddress, outputCurrency]
        amountIn = dependentValueMaximum
        amountOut = outputValueParsed
        nasValue = formatNasBalance(dependentValueMaximum)
      }

      if (swapType === TOKEN_TO_NAS) {
        path = [inputCurrency, wNAS.exchangeAddress]
        amountIn = dependentValueMaximum
        amountOut = outputValueParsed
      }

      if (swapType === TOKEN_TO_TOKEN) {
        path = [inputCurrency, outputCurrency]
        amountIn = dependentValueMaximum
        amountOut = outputValueParsed
      }
    }

    // console.log('onSwap params', {
    //   swapType,
    //   independentField,
    //   path,
    //   amountIn: amountIn.toString(),
    //   amountOut: amountOut.toString(),
    //   nasValue
    // })

    // detect if use nas nano
    // open qrcode modal

    const amountInBig = new BigNumber(amountIn)
    const amountOutBig = new BigNumber(amountOut)

    // console.log('amountInBig', amountInBig.toString(10))
    // console.log('amountOutBig', amountOutBig, amountOut.toString())

    const params = {
      swapType,
      independentField,
      path,
      amountIn: amountInBig.toString(10),
      amountOut: amountOutBig.toString(10),
      nasValue,
      toAddress: recipient.address
    }

    const contractParams = getSwapContractParams({ ...params })

    if (useNano) {
      onShowSwapModal(contractParams)
    } else {
      try {
        const txhash = await onSwap({
          ...params
        })

        addTransaction(txhash)
      } catch (err) {
        console.log('on swap error', err)
      }
    }
  }

  const [customSlippageError, setcustomSlippageError] = useState('')

  // const toggleWalletModal = useWalletModalToggle()

  // open wallet provider select modal
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)

  return (
    <>
      <NavigationTabs />
      <CurrencyInputPanel
        title={t('input')}
        urlAddedTokens={urlAddedTokens}
        description={inputValueFormatted && independentField === OUTPUT ? estimatedText : ''}
        extraText={inputBalanceFormatted && formatBalance(inputBalanceFormatted)}
        extraTextClickHander={() => {
          if (inputBalance && inputDecimals) {
            const valueToSet = inputBalance
            if (valueToSet.gt(ethers.constants.Zero)) {
              dispatchSwapState({
                type: 'UPDATE_INDEPENDENT',
                payload: {
                  value: amountFormatter(valueToSet, inputDecimals, inputDecimals, false),
                  field: INPUT
                }
              })
            }
          }
        }}
        onCurrencySelected={inputCurrency => {
          dispatchSwapState({
            type: 'SELECT_CURRENCY',
            payload: { currency: inputCurrency, field: INPUT }
          })
        }}
        onValueChange={inputValue => {
          dispatchSwapState({
            type: 'UPDATE_INDEPENDENT',
            payload: { value: inputValue, field: INPUT }
          })
        }}
        showUnlock={showUnlock}
        // selectedTokens={[inputCurrency, outputCurrency]}
        selectedTokenAddress={inputCurrency}
        value={inputValueFormatted}
        errorMessage={inputError ? inputError : independentField === INPUT ? independentError : ''}
      />

      <OversizedPanel>
        <DownArrowBackground>
          <DownArrow
            onClick={() => {
              dispatchSwapState({ type: 'FLIP_INDEPENDENT' })
            }}
            clickable
            alt="swap"
            active={isValid}
          />
        </DownArrowBackground>
      </OversizedPanel>
      <CurrencyInputPanel
        title={t('output')}
        description={outputValueFormatted && independentField === INPUT ? estimatedText : ''}
        extraText={outputBalanceFormatted && formatBalance(outputBalanceFormatted)}
        extraTextClickHander={() => {
          if (outputBalance && outputDecimals) {
            const valueToSet = outputBalance
            if (valueToSet.gt(ethers.constants.Zero)) {
              dispatchSwapState({
                type: 'UPDATE_INDEPENDENT',
                payload: {
                  value: amountFormatter(valueToSet, outputDecimals, outputDecimals, false),
                  field: OUTPUT
                }
              })
            }
          }
        }}
        urlAddedTokens={urlAddedTokens}
        onCurrencySelected={outputCurrency => {
          dispatchSwapState({
            type: 'SELECT_CURRENCY',
            payload: { currency: outputCurrency, field: OUTPUT }
          })
        }}
        onValueChange={outputValue => {
          dispatchSwapState({
            type: 'UPDATE_INDEPENDENT',
            payload: { value: outputValue, field: OUTPUT }
          })
        }}
        // selectedTokens={[inputCurrency, outputCurrency]}
        selectedTokenAddress={outputCurrency}
        value={outputValueFormatted}
        errorMessage={independentField === OUTPUT ? independentError : ''}
        disableUnlock
      />

      {sending ? (
        <>
          <OversizedPanel>
            <DownArrowBackground>
              <DownArrow active={isValid} alt="arrow" />
            </DownArrowBackground>
          </OversizedPanel>
          <AddressInputPanel onChange={setRecipient} onError={setRecipientError} initialInput={recipient} />
        </>
      ) : (
        ''
      )}
      <OversizedPanel hideBottom>
        <ExchangeRateWrapper
          onClick={() => {
            setInverted(inverted => !inverted)
          }}
        >
          <ExchangeRate>{t('exchangeRate')}</ExchangeRate>
          {inverted ? (
            <span>
              {exchangeRate
                ? `1 ${inputSymbol} = ${amountFormatter(exchangeRate, 6, 6, false)} ${outputSymbol}`
                : ' - '}
            </span>
          ) : (
            <span>
              {exchangeRate
                ? `1 ${outputSymbol} = ${amountFormatter(exchangeRateInverted, 6, 6, false)} ${inputSymbol}`
                : ' - '}
            </span>
          )}
        </ExchangeRateWrapper>
      </OversizedPanel>
      <TransactionDetails
        account={account}
        setRawSlippage={setRawSlippage}
        setRawTokenSlippage={setRawTokenSlippage}
        rawSlippage={rawSlippage}
        slippageWarning={slippageWarning}
        highSlippageWarning={highSlippageWarning}
        // brokenTokenWarning={brokenTokenWarning}
        // setDeadline={setDeadlineFromNow}
        // deadline={deadlineFromNow}
        inputError={inputError}
        independentError={independentError}
        inputCurrency={inputCurrency}
        outputCurrency={outputCurrency}
        independentValue={independentValue}
        independentValueParsed={independentValueParsed}
        independentField={independentField}
        INPUT={INPUT}
        inputValueParsed={inputValueParsed}
        outputValueParsed={outputValueParsed}
        inputSymbol={inputSymbol}
        outputSymbol={outputSymbol}
        dependentValueMinumum={dependentValueMinumum}
        dependentValueMaximum={dependentValueMaximum}
        dependentDecimals={dependentDecimals}
        independentDecimals={independentDecimals}
        percentSlippageFormatted={percentSlippageFormatted}
        setcustomSlippageError={setcustomSlippageError}
        recipientAddress={recipient.address}
        sending={sending}
      />
      <Flex>
        <Button
          disabled={!account && !error ? false : !isValid || customSlippageError === 'invalid'}
          onClick={account && !error ? onClickSwap : onPresentWalletProviderModal}
          warning={highSlippageWarning || customSlippageError === 'warning'}
          loggedOut={!account}
        >
          {!account
            ? t('connect-nebulas-wallet')
            : sending
            ? highSlippageWarning || customSlippageError === 'warning'
              ? t('sendAnyway')
              : t('send')
            : highSlippageWarning || customSlippageError === 'warning'
            ? t('swapAnyway')
            : t('swap')}
        </Button>
      </Flex>
    </>
  )
}
